import { Clone, useGLTF, useTexture } from "@react-three/drei";
import { MyContext } from "./data/contextapi";
import { useContext } from "react";
import * as THREE from "three";

export default function Handle() {
  const { width, height, handle, frameType } = useContext(MyContext);

  const grip = useGLTF(`./models/Handle ${handle}.glb`);

  const texture = useTexture(frameType);

  grip.scene.traverse((child) => {
    if (child.isMesh) {
      child.material = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide,
      });
    }
  });

  return (
    <>
      <Clone
        object={grip.scene}
        position={[
          0.03 * width + 0.1 - (handle === 5 || handle === 6 ? 0.6 : 0),
          -(0.053 * height) / 10000,
          0.045,
        ]}
        scale={handle === 7 ? 4.88 : handle === 4 ? 6 : 5}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <Clone
        object={grip.scene}
        position={[
          0.03 * width + 0.1 - (handle === 5 || handle === 6 ? 0.6 : 0),
          -(0.053 * height) / 10000,
          -0.045,
        ]}
        scale={handle === 7 ? 4.88 : handle === 4 ? 6 : 5}
        rotation={[
          0,
          [5, 6, 7, 8].includes(handle) ? Math.PI / 2 : -Math.PI / 2,
          [5, 6, 7, 8].includes(handle) ? 0 : Math.PI,
        ]}
      />
    </>
  );
}
