import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Endpoint from "./components/endpoint";
import RequestConfirmation from "./pages/RequestConfirmation";
import BubblesManager from "./pages/BubblesManager";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Endpoint />} />
        <Route path="/request-confirmation" element={<RequestConfirmation />} />
        <Route path="/manage-bubble" element={<BubblesManager />} />
      </Routes>
    </Router>
  );
}
