import { MyContext } from './data/contextapi';
import { useContext } from 'react';
import Structure from './Structure';

export default function Stalen() {
  const { width, height, stalenPart, stalenType, frameSize } = useContext(MyContext);

  const doorWidth = (0.06 * width) / stalenPart;
  const doorHeightn = 0.053 * height;

  return (
    <>
      {stalenType == 'tussen' ? (
        <>
          {stalenPart >= 1 && (
            <group
              position={[
                (0.06 * width + 4 * frameSize + (stalenPart - 1) * 3.5 * frameSize) / 2 - (doorWidth + (4 * frameSize + (stalenPart - 1) * 4 * frameSize) / stalenPart) / 2 - 3 * frameSize/2,
                (-12 + doorHeightn) * 0.5,
                -13.5,
              ]}
            >
              <group position={[0, 0, 0]}>
                <Structure sizePannel={0} stalenWidth={doorWidth} />
              </group>
            </group>
          )}
          {stalenPart >= 2 && (
            <group
              position={[
                (0.06 * width + 4 * frameSize + (stalenPart - 1) * 3.5 * frameSize) / 2 - 3 * (doorWidth + (4 * frameSize + (stalenPart - 1) * 4 * frameSize) / stalenPart) / 2 - frameSize,
                (-12 + doorHeightn) * 0.5,
                -13.5,
              ]}
              rotation={[0, Math.PI, 0]}
            >
              <group
                position={[0, 0, 0]}
                rotation={[0, Math.PI, 0]}
              >
                <Structure sizePannel={0} stalenWidth={doorWidth} />
              </group>
            </group>
          )}
          {stalenPart >= 3 && (
            <group
              position={[
                (0.06 * width + 4 * frameSize + (stalenPart - 1) * 3.5 * frameSize) / 2 - 5 * (doorWidth + (4 * frameSize + (stalenPart - 1) * 4 * frameSize) / stalenPart) / 2,
                (-12 + doorHeightn) * 0.5,
                -13.5,
              ]}
            >
              <group position={[0, 0, 0]}>
                <Structure sizePannel={0} stalenWidth={doorWidth} />
              </group>
            </group>
          )}
          {stalenPart >= 4 && (
            <group
              position={[
                (0.06 * width + 4 * frameSize + (stalenPart - 1) * 3.5 * frameSize) / 2 - 7 * (doorWidth + (4 * frameSize + (stalenPart - 1) * 4 * frameSize) / stalenPart) / 2 + frameSize / 2,
                (-12 + doorHeightn) * 0.5,
                -13.5,
              ]}
            >
              <group position={[0, 0, 0]}>
                <Structure sizePannel={0} stalenWidth={doorWidth} />
              </group>
            </group>
          )}
        </>
      ) : (
        <>
          {stalenPart >= 1 && (
            <group
              position={[
                2 * (0.06 * width + 4 * frameSize) - (doorWidth + 4 * frameSize),
                (-12 + doorHeightn) * 0.5,
                -13.5,
              ]}
              rotation={[0, Math.PI, 0]}
            >
              <group
                position={[-doorWidth / 2, 0, 0]}
                rotation={[0, Math.PI, 0]}
              >
                <Structure sizePannel={0} stalenWidth={doorWidth} />
              </group>
            </group>
          )}
          {stalenPart >= 2 && (
            <group
              position={[
                2 * (0.06 * width + 4 * frameSize) - 2 * (doorWidth + 4 * frameSize) + frameSize / 2,
                (-12 + doorHeightn) * 0.5,
                -13.5,
              ]}
              rotation={[0, Math.PI, 0]}
            >
              <group
                position={[-doorWidth / 2, 0, 0]}
                rotation={[0, Math.PI, 0]}
              >
                <Structure sizePannel={0} stalenWidth={doorWidth} />
              </group>
            </group>
          )}
          {stalenPart >= 3 && (
            <group
              position={[
                2 * (0.06 * width + 4 * frameSize) - 3 * (doorWidth + 4 * frameSize) + frameSize,
                (-12 + doorHeightn) * 0.5,
                -13.5,
              ]}
              rotation={[0, Math.PI, 0]}
            >
              <group
                position={[-doorWidth / 2, 0, 0]}
                rotation={[0, Math.PI, 0]}
              >
                <Structure sizePannel={0} stalenWidth={doorWidth} />
              </group>
            </group>
          )}
          {stalenPart >= 4 && (
            <group
              position={[
                2 * (0.06 * width + 4 * frameSize) - 4 * (doorWidth + 4 * frameSize) + 3 * frameSize/2,
                (-12 + doorHeightn) * 0.5,
                -13.5,
              ]}
              rotation={[0, Math.PI, 0]}
            >
              <group
                position={[-doorWidth / 2, 0, 0]}
                rotation={[0, Math.PI, 0]}
              >
                <Structure sizePannel={0} stalenWidth={doorWidth} />
              </group>
            </group>
          )}
        </>
      )}
    </>
  );
}
