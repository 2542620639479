import { useContext, useState } from "react";
import { MyContext } from "../data/contextapi";
import InfoIcon from "./InfoIcon";

export default function Extra() {
  const { settype, type, setStep, extraOptions, setExtraOptions } =
    useContext(MyContext);
  const [extras, setExtra] = useState([]);
  const [montage, setMontage] = useState([]);

  const handleExtra = (val) =>
    setExtraOptions((prev) =>
      prev.includes(val)
        ? [...prev.filter((itm) => itm != val)]
        : [...prev, val]
    );

  return (
    <>
      <div style={{ backgroundColor: "#fff" }}>
      <div style={{ width: "100%" }}>
          <div
            style={{
              margin: "5%",
              paddingTop: "0.5rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontWeight: "bolder",
              fontSize: "normal",
              paddingLeft: "10px",
            }}
          >
            <InfoIcon title={"Kies hier wat van toepassing is"} />
            {/* <div
              style={{
                backgroundColor: "white",
                borderRadius: "20px",
                width: "auto",
                padding: "2px 10px",
                margin: "0 0 0 10px",
              }}
            >
              <span
                className="body-txt"
                style={{}}
                onClick={() => {
                  setStep("door");
                }}
              >
                &euro; 400,00
              </span>
            </div> */}
          </div>
        </div>
        <div style={{ margin: "5%", fontSize: "small" }}>
          {[
            "Meetservice",
            "Montage service",
            "Taatsbeslag in kleur",
            "Vloerverwarming montageset",
            "Adviesgesprek",
          ].map((option, idx) => (
            <div
              key={`${option}_${idx}`}
              style={{ display: "flex", cursor: "pointer" }}
            >
              <input
                type="checkbox"
                checked={extraOptions.includes(option)}
                onChange={() => handleExtra(option)}
                style={{ marginRight: "10px", width: "15px", height: "15px" }}
              />
              <span onClick={() => handleExtra(option)}>{option}</span>
            </div>
          ))}
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              margin: "5%",
              paddingTop: "0.5rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontWeight: "bolder",
              fontSize: "normal",
              paddingLeft: "5px",
            }}
          >
            <InfoIcon title={"Afhalen of verzenden"} />
            {/* <div
              style={{
                backgroundColor: "white",
                borderRadius: "20px",
                width: "auto",
                padding: "2px 10px",
                margin: "0 0 0 10px",
              }}
            >
              <span
                className="body-txt"
                style={{}}
                // onClick={() => {
                //   setStep('door');
                // }}
              >
                &euro; 400,00
              </span>
            </div> */}
          </div>
        </div>
        <div
          style={{
            margin: "5%",
            fontSize: "small",
            borderBottom: "1px solid #d1d1d1",
            paddingBottom: "25px",
          }}
        >
          {[
            "Afleveren NL per koeriersdienst (excl. Waddeneilanden)",
            "Afleveren buiten NL",
            "Afhalen fabriek",
          ].map((option, idx) => (
            <div key={`${option}_${idx}`} style={{ display: "flex" }}>
              <input
                type="checkbox"
                checked={extraOptions.includes(option)}
                onChange={() => handleExtra(option)}
                style={{ marginRight: "10px", width: "15px", height: "15px" }}
              />
              <span onClick={() => handleExtra(option)}>{option}</span>
            </div>
          ))}
        </div>
        <button
          className="volgende btn"
          onClick={() => {
            setStep("information");
          }}
        >
          Volgende
        </button>
      </div>
    </>
  );
}
