import React, { useEffect, useState } from "react";

export default function ConsentBanner() {
  const [isConsentGiven, setIsConsentGiven] = useState(null);

  // Check if consent has already been given when the component mounts
  useEffect(() => {
    const savedConsent = localStorage.getItem("userConsent"); // Check for consent in localStorage
    if (savedConsent) {
      setIsConsentGiven(savedConsent === "true"); // Set consent status based on localStorage value
    }
  }, []);

  // When the user makes a decision, update the consent status and hide the banner
  useEffect(() => {
    if (isConsentGiven === true) {
      loadGA4();
      grantConsentLevel3();
      localStorage.setItem("userConsent", "true"); // Save consent to localStorage
    } else if (isConsentGiven === false) {
      denyConsentLevel3();
      localStorage.setItem("userConsent", "false"); // Save rejection to localStorage
    }
  }, [isConsentGiven]);

  // Load Google Analytics 4 script dynamically
  const loadGA4 = () => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-LMET8WCFVX";
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-LMET8WCFVX"); // GA4 Measurement ID
  };

  // Grant Consent (Analytics and Marketing Cookies)
  const grantConsentLevel3 = () => {
    window.gtag("consent", "update", {
      ad_storage: "granted",
      analytics_storage: "granted",
    });
  };

  // Deny Consent (Block Analytics and Marketing Cookies)
  const denyConsentLevel3 = () => {
    window.gtag("consent", "update", {
      ad_storage: "denied",
      analytics_storage: "denied",
    });
  };

  // If consent is already given, don't show the banner
  if (isConsentGiven !== null) {
    return null; // Don't render anything if the user has already accepted or denied consent
  }

  // Display the banner if the user hasn't made a choice yet
  return (
    <div className="consent-banner">
      <p>
        We use cookies for analytics and marketing. Do you agree to the use of
        these cookies?
      </p>
      <button onClick={() => setIsConsentGiven(true)}>Yes, I consent</button>
      <button onClick={() => setIsConsentGiven(false)}>No, I don't consent</button>
    </div>
  );
}
