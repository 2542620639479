import React, { useEffect } from "react";

export default function RequestConfirmation() {
  useEffect(() => {
    // Function to retrieve the GA Client ID
    const getGAClientId = () => {
      const trackers = window.ga?.getAll?.();
      if (trackers && trackers.length) {
        return trackers[0].get('clientId');
      }
      return null;
    };

    const timer = setTimeout(() => {
      const vid = window?.gaGlobal.vid;
      console.log("gaClientId", vid)
      // If GA Client ID is found, append it to the URL
      const redirectUrl = vid
        ? `https://cloozdoors.nl?gaClientId=${vid}`
        : "https://cloozdoors.nl"; // fallback if GA Client ID is not found
      window.location.href = redirectUrl;
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <div className="w-full h-20 bg-[#bdc79d]"></div>
      <div className="mt-10 p-8">
        <div className="max-w-5xl mx-auto bg-[#ececec] shadow-lg">
          <div className="p-6 pt-0 rounded-t-lg text-center">
            <div className="w-1/6">
              <img src="/images/Logo_CLOOZ.jpg" className="w-full" />
            </div>
          </div>
          <div className="p-6 text-left px-[15%]">
            <h2 className="text-4xl font-bold mb-0">
              BEDANKT VOOR UW AANVRAAG!
            </h2>
            <p className="text-gray-700 mb-8">
              Binnen 48 uur neemt onze verkoop binnendienst contact met u op om
              deze online aanvraag volledig te optimaliseren en u van de juiste
              offerte te voorzien.
            </p>
          </div>
        </div>
        <div className="max-w-5xl mx-auto mt-4 shadow-lg">
          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <div className="bg-[#bdc79d] border">
                <div className="px-4 pt-6 pb-4">
                  <h3 className="font-semibold text-white text-xl">
                    Waarom kies
                  </h3>
                  <h3 className="font-semibold text-white text-xl">
                    Je Clooz doors
                  </h3>
                </div>
                <div className="p-2 bg-[#000]">
                  <img src="/images/request confirm/Why choose.jpg" />
                </div>

                <div className="px-4 pt-3 pb-4">
                  <ul className="text-left text-white text-base list-disc list-inside">
                    <li>Eigen fabriek</li>
                    <li>Uniek eigen profiel</li>
                    <li>Snelste levering</li>
                    <li>Perfecte afwerking</li>
                    <li>Kindveilig product</li>
                  </ul>
                </div>
              </div>
              <div className="bg-[#bdc79d] border">
                <div className="px-4 pt-6 pb-4">
                  <h3 className="font-semibold text-white text-xl">
                    Waarom het
                  </h3>
                  <h3 className="font-semibold text-white text-xl">
                  Clooz doors profiel?
                  </h3>
                </div>
                <div className="p-2 bg-[#000]">
                  <img src="/images/request confirm/Why it.jpg" />
                </div>

                <div className="px-4 pt-3 pb-4">
                  <ul className="text-left text-white text-base list-disc list-inside">
                    <li>Tweezijdig hetzelfde aanzicht</li>
                    <li>Elke design mogelijk</li>
                    <li>Ongedeelde glasplaat</li>
                    <li>Rankste profiel</li>
                  </ul>
                </div>
              </div>
              <div className="bg-[#bdc79d] border">
                <div className="px-4 pt-6 pb-4">
                  <h3 className="font-semibold text-white text-xl">
                    Bezoek onze
                  </h3>
                  <h3 className="font-semibold text-white text-xl">SHOWROOM</h3>
                </div>
                <div className="p-2 bg-[#000]">
                  <img src="/images/request confirm/Visit our.jpg" />
                </div>
                <div className="px-4 pt-3 pb-4">
                  <ul className="text-left text-white text-base list-disc list-inside">
                    <li>Met persoonlijk advies</li>
                    <li>Alle deuren</li>
                    <li>Ontwerp samen</li>
                    <li>Ook op zaterdag</li>
                    <li>Centraal in NL</li>
                  </ul>
                </div>
              </div>
              <div className="bg-[#bdc79d] border">
                <div className="px-4 pt-6 pb-4">
                  <h3 className="font-semibold text-white text-xl">
                    Gebruik onze
                  </h3>
                  <h3 className="font-semibold text-white text-xl">
                    inmeetservice
                  </h3>
                </div>
                <div className="p-2 bg-[#000]">
                  <img src="/images/request confirm/Use our.jpg" />
                </div>

                <div className="px-4 pt-3 pb-4">
                  <ul className="text-left text-white text-base list-disc list-inside">
                    <li>Door heel NL</li>
                    <li>Voorkom fouten</li>
                    <li>Voorkom kosten</li>
                    <li>Met het beste advies</li>
                    <li>Voor het gemak</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
