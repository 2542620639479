import { IoMdClose } from "react-icons/io";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { RiSearchLine } from "react-icons/ri";
import rals from "./logic/data/colors.json";
import { MyContext } from "./logic/data/contextapi";

export default function ColorSelector() {
  const { colorPickerOpened, setColorPickerOpened, setFrameType, setCustomFrameType } = useContext(MyContext);
  const iconSize = "16px";
  const [value, setValue] = useState("");
  const handleChange = (event) => setValue(event.target.value);

  const colors = rals;

  let resColors = [];

  for (const [key, value] of Object.entries(colors)) {
    resColors.push(value);
  }

  if (value) {
    resColors = resColors.filter((x) =>
      x.code.startsWith(value.toLowerCase().trim())
    );
  }

  return (
    <div
      className={
        "absolute bg-neutral-200 bottom-0 left-[108px] flex flex-col " +
        (!colorPickerOpened ? "hidden" : "")
      }
      style={{ width: "180px" }}
    >
      <div className="bg-gray-200 p-2 text-xs flex flex-row items-center">
        <div>Color selector</div>
        <div className="ml-auto">
          <IoMdClose
            className="cursor-pointer"
            onClick={() => setColorPickerOpened(false)}
            size={iconSize}
          />
        </div>
      </div>

      <div className="p-1 flex flex-col gap-2">
        <div className="relative">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <RiSearchLine className="text-gray-300" />
          </span>
          <input
            value={value}
            onChange={handleChange}
            type="text"
            placeholder="RAL code"
            className="pl-8 pr-2 py-1 border border-gray-300 rounded bg-white text-xs w-full"
          />
        </div>

        <div className="" style={{ overflowY: "scroll", maxHeight: "350px" }}>
          <div className="flex flex-row flex-wrap gap-1">
            {resColors.map((c) => {
              return (
                <div
                  onClick={() => {
                    setFrameType(`./images/custom_colors/${c.img}`);
                    setCustomFrameType({name: c.names.en, color: c.color.hex})
                    setColorPickerOpened(false);
                  }}
                  key={c.code}
                  className="flex h-5 w-9 text-xs items-center justify-center text-white cursor-pointer"
                  style={{ background: c.color.hex }}
                >
                  {c.code}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
