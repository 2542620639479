// src/BubblesManager.js
import React, { useEffect, useState } from "react";
import axios from "axios";

const BubblesManager = () => {
  const [bubbles, setBubbles] = useState([]);
  const [bubbleName, setBubbleName] = useState("");
  const [bubbleText, setBubbleText] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [currentBubbleIndex, setCurrentBubbleIndex] = useState(null);

  useEffect(() => {
    fetchBubbles();
  }, []);

  const fetchBubbles = async () => {
    try {
      const response = await axios.get(
        "https://api.config-fencing.com/api/get-bubbles"
      );
      setBubbles(response.data.bubbles);
    } catch (error) {
      console.error("Error fetching bubbles:", error);
    }
  };

  const addBubble = async () => {
    if (bubbleName.trim() !== "" && bubbleText.trim() !== "") {
      const response = await axios.post(
        "https://api.config-fencing.com/api/create-bubble",
        {
          name: bubbleName,
          text: bubbleText,
        }
      );
      setBubbles([
        ...bubbles,
        { id: response.data.bubble.id, name: bubbleName, text: bubbleText },
      ]);
      setBubbleName("");
      setBubbleText("");
    }
  };

  const editBubble = (index) => {
    setBubbleName(bubbles[index].name);
    setBubbleText(bubbles[index].text);
    setIsEditing(true);
    setCurrentBubbleIndex(index);
  };

  const updateBubble = async () => {
    const response = await axios.post(
      `https://api.config-fencing.com/api/update-bubble/${bubbles[currentBubbleIndex].id}`,
      {
        name: bubbleName,
        text: bubbleText,
      }
    );
    const updatedBubbles = bubbles.map((bubble, index) =>
      index === currentBubbleIndex
        ? { id: bubble.id, name: bubbleName, text: bubbleText }
        : bubble
    );
    setBubbles(updatedBubbles);
    setBubbleName("");
    setBubbleText("");
    setIsEditing(false);
    setCurrentBubbleIndex(null);
  };

  const removeBubble = (index) => {
    const newBubbles = bubbles.filter((_, i) => i !== index);
    setBubbles(newBubbles);
  };

  return (
    <div className="container mx-auto p-6 max-w-md">
      <h1 className="text-3xl font-bold mb-6 text-center">Bubble Manager</h1>
      {/* <div className="mb-6">
        <input
          type="text"
          value={bubbleName}
          onChange={(e) => setBubbleName(e.target.value)}
          className="border rounded p-2 w-full mb-4 focus:outline-none focus:ring-2 focus:ring-blue-400"
          placeholder="Enter bubble name"
        />
        <input
          type="text"
          value={bubbleText}
          onChange={(e) => setBubbleText(e.target.value)}
          className="border rounded p-2 w-full mb-4 focus:outline-none focus:ring-2 focus:ring-blue-400"
          placeholder="Enter bubble text"
        />
        {isEditing ? (
          <button
            onClick={updateBubble}
            className="bg-yellow-500 text-white rounded p-2 w-full hover:bg-yellow-600"
          >
            Update Bubble
          </button>
        ) : (
          <button
            onClick={addBubble}
            className="bg-blue-500 text-white rounded p-2 w-full hover:bg-blue-600"
          >
            Add Bubble
          </button>
        )}
      </div> */}
      {isEditing && (
        <div className="mb-6">
          <input
            type="text"
            value={bubbleName}
            onChange={(e) => setBubbleName(e.target.value)}
            className="border rounded p-2 w-full mb-4 focus:outline-none focus:ring-2 focus:ring-blue-400"
            placeholder="Enter bubble name"
          />
          <input
            type="text"
            value={bubbleText}
            onChange={(e) => setBubbleText(e.target.value)}
            className="border rounded p-2 w-full mb-4 focus:outline-none focus:ring-2 focus:ring-blue-400"
            placeholder="Enter bubble text"
          />
          <button
            onClick={updateBubble}
            className="bg-yellow-500 text-white rounded p-2 w-full hover:bg-yellow-600"
          >
            Update Bubble
          </button>
        </div>
      )}

      <ul className="list-none p-0">
        {bubbles.map((bubble, index) => (
          <li
            key={index}
            className="flex justify-between items-center mb-2 bg-gray-100 p-2 rounded shadow"
          >
            <div className="flex-1">
              <h2 className="font-bold">{bubble.name}</h2>
              <p>{bubble.text}</p>
            </div>
            <div>
              <button
                onClick={() => editBubble(index)}
                className="bg-yellow-500 text-white rounded p-1 mr-2 hover:bg-yellow-600"
              >
                Edit
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BubblesManager;
