import React from "react";

const Tooltip = ({ message, children }) => {
  return (
    <div className="relative group">
      {children}
      {message !== "" && (
        <div className="absolute top-[30px] left-1/2 w-[140px] transform -translate-x-1/2 mb-2 hidden group-hover:block z-10">
          <div className="relative bg-gray-800 text-white text-xs rounded py-1 px-4">
            {message}
            <div className="absolute top-[-5px] left-1/2 transform -translate-x-1/2 w-3 h-3 bg-gray-800 rotate-45"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
