import { MyContext } from "./data/contextapi";
import { useContext, useEffect, useRef } from "react";
import { useTexture } from "@react-three/drei";
import * as THREE from "three";

class CustomCircleCurve extends THREE.Curve {
  constructor(radius = 1, segments = 64, fragment = 1) {
    super();
    this.radius = radius;
    this.segments = segments;
    this.fragment = fragment;
  }

  getPoint(t, optionalTarget = new THREE.Vector3()) {
    const angle = t * Math.PI * this.fragment;
    const x = Math.cos(angle) * this.radius * 0.5;
    const y = Math.sin(angle) * this.radius * 0.5;
    return optionalTarget.set(x, y, 0);
  }
}

export default function Structure({ isSide, isRotated, stalenWidth }) {
  const {
    door,
    width,
    height,
    frameSize,
    glassType,
    frameType,
    stalenPart,
    doorConfig,
    sidePannelConfig,
    sidePannel,
    setWidth,
    sidePannelSize,
    open,
  } = useContext(MyContext);
  const texture = useTexture(frameType);

  const frameWidth = 0.19;
  const frameHeight = 0.054 * height;
  const frameDepth = 0.09;

  let interframeWidth;
  let interframeHeight;
  let interframeWidth2;
  let interframeHeight2;
  let interframeWidth3;
  let interframeHeight3;
  let interframeDepth = 0.19 / 2;
  let positioner;
  let positioner2;
  let positioner3;

  let xPosition = [];
  let yPosition = [];
  let unitWidth = [];
  let unitHeight = [];

  // const doorWidth = (0.06 * width) / stalenPart - sizePannel * 0.06;
  const doorRef =
    sidePannelConfig == "eigen maat"
      ? isSide
        ? sidePannelSize
        : width
      : width;

  const doorWidth = stalenWidth ? stalenWidth :
    (sidePannelConfig == "eigen maat"
      ? isSide
        ? 0.06 * sidePannelSize
        : 0.06 * width
      : 0.06 * width);
  const doorHeightn = 0.053 * height;
  const doorDepth = 0.02;

  if (door == "3panel" || door == "herenhuis") {
    interframeWidth = doorWidth + frameSize;
    interframeHeight = frameSize * 0.5;
    interframeWidth2 = frameSize * 0.5;
    interframeHeight2 = 0.053 * height;
    positioner = 4;
    positioner2 = 10000;
  } else if (
    door == "herman" ||
    door == "4panel" ||
    door == "elody" ||
    door == "3pannel" ||
    door == "drentse" ||
    door == "boerderij" ||
    door == "fabric" ||
    door == "larino" ||
    door == "larino1" ||
    door == "toog" ||
    door == "low-deep" ||
    door == "grandma" ||
    door == "staldeuren" ||
    door == "contempera" ||
    door == "80s" ||
    door == "2panel" ||
    door == "dt3" ||
    door == "dt2" ||
    door == "parallel" ||
    door == "notaris" ||
    door == "toogPlus" ||
    door == "rond" ||
    door == "rondPlus" ||
    door == "porto" ||
    door == "rivera" ||
    door == "dt11" ||
    door == "boender" ||
    door == "dt13" ||
    door == "kasteel" ||
    door == "kathedraal" ||
    door == "gladiator" ||
    door == "the-judge" ||
    door == "prison" ||
    door == "curved" ||
    door == "lindsey" ||
    door == "baku" ||
    door == "supreme" ||
    door == "ultimate" ||
    door == "fisherman" ||
    door == "primier" ||
    door == "elite"
  ) {
    interframeWidth = doorWidth + frameSize;
    interframeHeight = frameSize * 0.5;
    interframeWidth2 = frameSize * 0.5;
    interframeHeight2 = 0.053 * height + frameSize;
    if (door == "dt3") {
      positioner = 3;
    } else if (door == "dt2") {
      positioner = 10000;
    } else if (door == "parallel") {
      interframeWidth = (0.033 * doorRef) / stalenPart + frameSize;
      interframeHeight = frameSize * 0.5;
      interframeWidth2 = frameSize * 0.5;
      interframeHeight2 = 0.043 * height;
      interframeWidth3 = frameSize * 0.5;
      interframeHeight3 = 0.005 * height;
      positioner = 2.5;
      positioner2 = -4;
    } else if (door == "notaris") {
      interframeWidth = (0.043 * doorRef) / stalenPart + frameSize;
      interframeHeight = frameSize * 0.5;
      interframeWidth2 = frameSize * 0.5;
      interframeHeight2 =
        doorHeightn - (doorWidth - interframeWidth) + frameSize;
      interframeWidth3 = frameSize * 0.5;
      interframeHeight3 = (doorWidth - interframeWidth) / 2;
      positioner = 2.5;
      positioner2 = -4;
    } else {
      positioner = 3.5;
    }

    if (door == "boerderij" || door == "4panel" || door == "2panel") {
      positioner2 = 10000;
    } else if (door == "dt3") {
      positioner2 = -8.5;
    } else {
      positioner2 = -4;
    }
  } else if (door == "lucas" || door == "karel" || door == "annelot") {
    interframeWidth = doorWidth + frameSize;
    interframeHeight = frameSize * 0.5;
    interframeWidth2 = frameSize * 0.5;
    interframeHeight2 = 0.053 * height;
    positioner = 12;
    positioner2 = -4;
  } else if (
    door == "govert" ||
    door == "rembrandt" ||
    door == "low-deep" ||
    door == "dt2"
  ) {
    interframeWidth = doorWidth + frameSize;
    interframeHeight = frameSize * 0.5;
    interframeWidth2 = frameSize * 0.5;
    interframeHeight2 = 0.053 * height;
    positioner2 = -4;
  } else {
    positioner = 0;
    positioner2 = 0;
    interframeWidth = 0;
    interframeHeight = 0;
    interframeWidth2 = 0;
    interframeHeight2 = 0;
  }

  const radius = (doorWidth - interframeWidth) / 2; // Radius of the half-circle

  // Create a custom shape resembling a half-circle
  const halfCircleShape = new THREE.Shape();
  halfCircleShape.moveTo(0, radius);
  halfCircleShape.absarc(0, 0, radius, 0, Math.PI, false);

  // Define extrude settings
  const extrudeSettings = {
    steps: 2, // Number of steps for extrusion
    depth: interframeDepth, // Depth of extrusion
    bevelEnabled: false, // Disable beveling
  };

  const radiusn = 0.01 * height; // Define the radius of the circle
  const segments = 5; // Define the number of segments in the circle
  const path = new CustomCircleCurve(radiusn, segments, 0.7);

  const dt7Radiusn = doorWidth * 1.6; // Define the radius of the circle
  const dt7Path = new CustomCircleCurve(dt7Radiusn, segments, 0.5);
  const dt7Path2 = new CustomCircleCurve(dt7Radiusn, segments);
  const dt7Radius = dt7Path2.getPoint(1).x;

  const dt8Radiusn = doorWidth * 1.65; // Define the radius of the circle
  const dt8Path = new CustomCircleCurve(dt8Radiusn, segments);
  const dt8Radius = dt8Path.getPoint(1).x;

  const dt9Radiusn = 0.012 * height;
  const dt9Path = new CustomCircleCurve(dt9Radiusn, segments, 0.5);
  const dt9Radius = dt9Path.getPoint(1).y;

  const kasteelRadiusn = doorWidth * (2 / 3) * 2; // Define the radius of the circle
  const kasteelPath = new CustomCircleCurve(kasteelRadiusn, segments, 0.5);
  const kasteelPath2 = new CustomCircleCurve(kasteelRadiusn, segments);
  const kasteelRadius = kasteelPath2.getPoint(1).x;

  const prisonRadiusn = doorWidth * (2.5 / 3) * 2; // Define the radius of the circle
  const prisonPath = new CustomCircleCurve(prisonRadiusn, segments, 0.5);
  const prisonPath2 = new CustomCircleCurve(prisonRadiusn, segments);
  const prisonRadius = prisonPath2.getPoint(1).x;
  
  const curvedRadiusn = doorWidth * (1 / 3) * 2; // Define the radius of the circle
  const curvedPath = new CustomCircleCurve(curvedRadiusn, segments, 0.5);
  const curvedPath2 = new CustomCircleCurve(curvedRadiusn, segments);
  const curvedRadius = curvedPath2.getPoint(1).x;

  const bakuRadiusn = doorWidth * (1 / 2) * 2; // Define the radius of the circle
  const bakuPath = new CustomCircleCurve(bakuRadiusn, segments, 0.5);
  const bakuPath2 = new CustomCircleCurve(bakuRadiusn, segments);
  const bakuRadius = bakuPath2.getPoint(1).x;

  const supremeRadiusn = doorWidth * (1 / 2) * 2; // Define the radius of the circle
  const supremePath = new CustomCircleCurve(supremeRadiusn, segments, 0.5);
  const supremePath2 = new CustomCircleCurve(supremeRadiusn, segments);
  const supremeRadius = supremePath2.getPoint(1).x;

  const ultimate1Radiusn = doorWidth * (1.1 / 2) * 2; // Define the radius of the circle
  const ultimate1Path = new CustomCircleCurve(ultimate1Radiusn, segments, 0.5);
  const ultimate1Path2 = new CustomCircleCurve(ultimate1Radiusn, segments);
  const ultimate1Radius = ultimate1Path2.getPoint(1).x;

  const ultimate2Radiusn = doorWidth * (1 / 2) * 2; // Define the radius of the circle
  const ultimate2Path = new CustomCircleCurve(ultimate2Radiusn, segments, 0.5);
  const ultimate2Path2 = new CustomCircleCurve(ultimate2Radiusn, segments);
  const ultimate2Radius = ultimate2Path2.getPoint(1).x;

  const ultimate3Radiusn = doorWidth * (1 / 4) * 2; // Define the radius of the circle
  const ultimate3Path = new CustomCircleCurve(ultimate3Radiusn, segments, 0.5);
  const ultimate3Path2 = new CustomCircleCurve(ultimate3Radiusn, segments);
  const ultimate3Radius = ultimate3Path2.getPoint(1).x;

  const fisherAngle = Math.asin(doorWidth * 2 / doorHeightn) * (180 / Math.PI);
  

  const eliteAngle1 = Math.asin((0.8 + frameSize) / (2.4 + frameSize)) * (180 / Math.PI)
  const eliteAngle2 = Math.asin(0.4 / (2.4 + frameSize)) * (180 / Math.PI);
  
  const eliteTotalAngle = (360 - eliteAngle1 * 4 - eliteAngle2 * 4) / 4;

  const fishermanRadiusn = 1.01 *doorHeightn / 2 * 2; // Define the radius of the circle
  const fishermanPath = new CustomCircleCurve(fishermanRadiusn, segments, fisherAngle / 180);
  const fishermanPath2 = new CustomCircleCurve(fishermanRadiusn, segments);
  const fishermanRadius = fishermanPath2.getPoint(1).x;

  const elite1Radiusn = (1.6 + frameSize) * 2; // Define the radius of the circle
  const elite1Path = new CustomCircleCurve(elite1Radiusn, segments, 0.5);
  const elite1Path2 = new CustomCircleCurve(elite1Radiusn, segments);
  const elite1Radius = elite1Path2.getPoint(1).x;

  const elite2Radiusn = (2.4 + 1.5 * frameSize) * 2; // Define the radius of the circle
  const elite2Path = new CustomCircleCurve(elite2Radiusn, segments, eliteTotalAngle / 180);
  const elite2Path2 = new CustomCircleCurve(elite2Radiusn, segments);
  const elite2Radius = elite2Path2.getPoint(1).x;

  let dt10Or13Radius = 0.0075 * height;

  if (door == "porto") {
    const fixedDoorWidth = interframeWidth;

    xPosition[0] = Math.abs(fixedDoorWidth / positioner2);

    unitWidth[1] =
      fixedDoorWidth - xPosition[0] - dt9Radius / 2 - 4 * frameSize;
    unitWidth[2] =
      (fixedDoorWidth - xPosition[0]) / 2 + (xPosition[0] * 0.8) / 2;
    xPosition[1] = xPosition[0] / 2 + dt9Radius / 2;
    xPosition[2] = -(fixedDoorWidth - unitWidth[2] + dt9Radius) / 2;
    xPosition[3] = -fixedDoorWidth / 2 + unitWidth[2];
    xPosition[5] = xPosition[0] - dt9Radius;
    xPosition[6] = xPosition[3] - dt9Radius;
    unitHeight[3] = (doorHeightn - xPosition[0]) / 2 - dt9Radius;
    unitHeight[4] = interframeHeight2 - xPosition[0] - dt9Radius;

    yPosition[1] = doorHeightn / 2 - xPosition[0];
    yPosition[3] = -(doorHeightn - unitHeight[3]) / 2;
    yPosition[4] = -xPosition[0] / 2 - dt9Radius / 2;
    yPosition[5] = yPosition[1] - dt9Radius;
    yPosition[6] = -xPosition[0] / 2 - dt9Radius;
    unitWidth[2] = unitWidth[2] - dt9Radius;
  } else if (door == "rivera") {
    xPosition[0] = xPosition[2] = Math.abs(interframeWidth / positioner2);
    xPosition[1] = xPosition[3] = -Math.abs(interframeWidth / positioner2);

    dt10Or13Radius = interframeWidth - xPosition[0] * 2;

    unitHeight[0] = unitHeight[1] =
      doorHeightn * (isSide ? 0.55 : 0.4) - dt10Or13Radius / 2;
    unitHeight[2] = unitHeight[3] =
      doorHeightn * (isSide ? 0.4 : 0.55) - dt10Or13Radius / 2;

    yPosition[0] = yPosition[1] = -(doorHeightn - unitHeight[0]) / 2;
    yPosition[2] = yPosition[3] = (doorHeightn - unitHeight[2]) / 2;
    yPosition[5] = -doorHeightn / 2 + unitHeight[0];
    yPosition[6] = doorHeightn / 2 - unitHeight[2];
  } else if (door == "dt13") {
    xPosition[0] = Math.abs(interframeWidth / positioner2);
    xPosition[1] = -Math.abs(interframeWidth / positioner2);

    dt10Or13Radius = interframeWidth - xPosition[0] * 2;

    unitHeight[0] = unitHeight[1] =
      doorHeightn - 2 * xPosition[0] - dt10Or13Radius / 2;
    unitWidth[4] = unitWidth[5] = xPosition[0];

    xPosition[4] = interframeWidth / 2 - unitWidth[4] / 2;
    xPosition[5] = -xPosition[4];

    yPosition[0] = yPosition[1] = 0;
    yPosition[2] = unitHeight[0] / 2;
    yPosition[3] = -unitHeight[0] / 2;
    yPosition[4] = yPosition[5] = 0;
  } else if (door == "dt11" || door == "boender") {
    xPosition[0] =
      (interframeWidth - (2 * interframeWidth) / 3) / 2 + frameSize / 2;
    unitWidth[0] = interframeWidth / 3;

    xPosition[1] = -interframeWidth / 2 + unitWidth[0] / 2;

    if (door == "boender") {
      yPosition[1] = doorHeightn / 2 / 1.6;
    }
  } else if (door == "fabric") {
    unitWidth[0] = interframeWidth;
    yPosition[0] = -doorHeightn / 4;
    yPosition[1] = -doorHeightn / 6.5;

    unitHeight[3] = doorHeightn / 2 - yPosition[1];
    unitHeight[4] = doorHeightn / 2 + yPosition[0];
    yPosition[3] = doorHeightn / 4 + yPosition[1] / 2;
    yPosition[4] = -doorHeightn / 2 - yPosition[0] / 2;
  } else if (door == "larino") {
    unitWidth[0] = interframeWidth / 2;
  } else if (door == "toog") {
    xPosition[0] = xPosition[2] = Math.abs(interframeWidth / 3);
    xPosition[1] = xPosition[3] = -Math.abs(interframeWidth / 3);

    dt10Or13Radius = xPosition[0] * 2;

    unitHeight[0] = unitHeight[1] = doorHeightn * 0.92 - dt10Or13Radius / 2;
    unitHeight[2] = unitHeight[3] =
      doorHeightn * (isSide ? 0.4 : 0.55) - dt10Or13Radius / 2;

    yPosition[0] = yPosition[1] = -(doorHeightn - unitHeight[0]) / 2;
    yPosition[2] = -doorHeightn / 2 + unitHeight[0];
  } else if (door == "larino1") {
    xPosition[0] = doorWidth / 3.5;
    unitWidth[1] = -doorWidth / 4.5;
    unitHeight[1] = unitWidth[1] * 1.5;
    yPosition[1] = unitHeight[1] / 2;
    xPosition[1] = interframeWidth / 2 + unitWidth[1] / 2;
  } else if (door == "grandma") {
    yPosition[0] = (doorHeightn / 2) * 0.85;
    yPosition[1] = -(doorHeightn / 2) * 0.85 + frameSize;
    xPosition[2] = interframeWidth / 2 - (doorHeightn / 2) * 0.15;
    xPosition[3] = -(interframeWidth / 2 - (doorHeightn / 2) * 0.15);
  } else if (door == "80s") {
    yPosition[0] = (doorHeightn / 2) * 0.65;
    yPosition[1] = -(doorHeightn / 2) * 0.65 + frameSize;
    xPosition[2] = interframeWidth / 2 - (doorHeightn / 2) * 0.15;
    xPosition[3] = -(interframeWidth / 2 - (doorHeightn / 2) * 0.15);
  } else if (door == "staldeuren") {
    yPosition[0] = doorHeightn / 4;
    yPosition[1] = 0;
    yPosition[2] = -doorHeightn / 4;
    yPosition[3] = -doorHeightn / 4 - doorHeightn / 8;
    unitHeight[3] = doorHeightn / 4;
  } else if (door == "contempera") {
    dt10Or13Radius = interframeWidth / 9;

    xPosition[0] =
      xPosition[2] =
      xPosition[5] =
      xPosition[7] =
        interframeWidth / 2 - interframeWidth / 3;
    xPosition[1] =
      xPosition[3] =
      xPosition[4] =
      xPosition[6] =
        -(interframeWidth / 2 - interframeWidth / 3);
    xPosition[8] = xPosition[1] - dt10Or13Radius / 2;
    xPosition[9] = xPosition[1] + dt10Or13Radius / 2;
    xPosition[10] = xPosition[0] - dt10Or13Radius / 2;
    xPosition[11] = xPosition[0] + dt10Or13Radius / 2;

    unitHeight[0] =
      unitHeight[1] =
      unitHeight[2] =
      unitHeight[3] =
        doorHeightn / 6;
    unitHeight[8] =
      unitHeight[9] =
      unitHeight[10] =
      unitHeight[11] =
        doorHeightn - 2 * unitHeight[0] - dt10Or13Radius + frameSize;

    yPosition[0] = yPosition[1] = doorHeightn / 2 - unitHeight[0] / 2;
    yPosition[2] = yPosition[3] = -(doorHeightn / 2 - unitHeight[0] / 2);
    yPosition[4] = yPosition[5] =
      doorHeightn / 2 - unitHeight[0] - dt10Or13Radius / 2;
    yPosition[6] = yPosition[7] = -(
      doorHeightn / 2 -
      unitHeight[0] -
      dt10Or13Radius / 2
    );
    yPosition[8] = yPosition[9] = yPosition[10] = yPosition[11] = 0;
    yPosition[12] = doorHeightn / 2 - unitHeight[0] - dt10Or13Radius / 6 + 0.07;
    yPosition[13] = -(doorHeightn / 2 - unitHeight[0] - dt10Or13Radius / 6) - 0.07;
  } else if (door == "annelot") {
    const gapWidth = doorWidth / positioner2;
    yPosition[1] = -(doorHeightn / 2 - 105 * 0.053);
    yPosition[2] = yPosition[1] + gapWidth;
  } else if (door == "kasteel") {
    unitHeight[2] = doorHeightn - 2.3 + kasteelRadius + kasteelRadius / 6;
    unitHeight[3] = doorHeightn - 2.3 / 2 + kasteelRadius;

    yPosition[0] = doorHeightn / 2 + kasteelRadius - 1.2;
    yPosition[1] = -doorHeightn / 2 - kasteelRadius + 1.2;
    yPosition[2] = doorHeightn / 2 - unitHeight[2] / 2 + kasteelRadius - 1.15;
    yPosition[3] = -doorHeightn / 2 + unitHeight[3] / 2 - kasteelRadius + 1.14;
    yPosition[4] = -doorHeightn / 2 + 1.2;

    xPosition[1] = -doorWidth / 2;
    xPosition[2] = doorWidth / 2 + kasteelRadius + frameSize;
    xPosition[3] = -doorWidth / 2 - kasteelRadius;
    xPosition[4] = 0;
  } else if (door == "kathedraal") {
    unitHeight[1] = doorHeightn + kasteelRadius - 1.2;

    const radianDiff =
      -kasteelRadius + 1.2 - doorHeightn / 3 > 0
        ? (-kasteelRadius + 1.2 - doorHeightn / 3) / 4.5
        : 0;

    unitWidth[2] = doorWidth / 3 + radianDiff + frameSize;
    unitWidth[3] = doorWidth / 3 + frameSize;

    yPosition[0] = doorHeightn / 2 + kasteelRadius - 1.2;
    yPosition[1] = doorHeightn / 2 - unitHeight[1] / 2 + kasteelRadius - 1.2;
    yPosition[2] = yPosition[4] = doorHeightn / 2 - doorHeightn / 3;
    yPosition[3] = yPosition[5] = -doorHeightn / 2 + doorHeightn / 3;

    xPosition[1] = doorWidth / 2 + kasteelRadius + frameSize;
    xPosition[2] = -doorWidth / 2 + unitWidth[2] / 2 - frameSize / 2;
    xPosition[3] = -doorWidth / 2 + unitWidth[3] / 2 - frameSize / 2;
    xPosition[4] = xPosition[5] = 0;
  } else if (door == "gladiator") {
    unitHeight[1] = doorHeightn + kasteelRadius - 1.2;

    const radianDiff =
      -kasteelRadius + 1.2 - doorHeightn / 3 > 0
        ? (-kasteelRadius + 1.2 - doorHeightn / 3) / 4.5
        : 0;

    unitWidth[2] = doorWidth / 3 + radianDiff;
    unitWidth[3] = doorWidth / 3;

    yPosition[0] = doorHeightn / 2 + kasteelRadius - 1.2;
    yPosition[1] = doorHeightn / 2 - unitHeight[1] / 2 + kasteelRadius - 1.2;
    yPosition[2] = doorHeightn / 2 - doorHeightn / 3;
    yPosition[3] = -doorHeightn / 2 + doorHeightn / 3;
    yPosition[4] = -doorHeightn / 2 + unitHeight[1] / 2;

    xPosition[1] = doorWidth / 2 + kasteelRadius + frameSize / 3;
    xPosition[2] = -doorWidth / 2 + unitWidth[2] / 2;
    xPosition[3] = -doorWidth / 2 + unitWidth[3] / 2;
    xPosition[4] = -doorWidth / 2 - kasteelRadius - frameSize / 3;
    xPosition[5] = doorWidth / 2 - unitWidth[2] / 2;
    xPosition[6] = doorWidth / 2 - unitWidth[3] / 2;
  } else if (door == "the-judge") {
    xPosition[0] = doorWidth / 2 - 1.2;
    xPosition[1] = doorWidth / 2 - 0.6 + frameSize / 2;

    yPosition[0] = 0;
    yPosition[1] = 0;

    unitWidth[1] = 1.2;
    unitHeight[1] = 2.4;
  } else if (door == "prison") {
    unitHeight[1] = doorHeightn + kasteelRadius - 1.2;

    const radianDiff =
      -prisonRadius + 1.2 - doorHeightn / 3 > 0
        ? (-prisonRadius + 1.2 - doorHeightn / 3) / 4.5
        : 0;

    unitWidth[2] = doorWidth / 3 + radianDiff;
    unitWidth[3] = doorWidth / 3;

    yPosition[0] = doorHeightn / 2 + prisonRadius - 1.2;
    yPosition[1] = doorHeightn / 2 - unitHeight[1] / 2 + prisonRadius - 1.2;
    yPosition[2] = yPosition[4] = doorHeightn / 2 - doorHeightn / 3;
    yPosition[3] = yPosition[5] = -doorHeightn / 2 + doorHeightn / 3;

    xPosition[1] = doorWidth / 2 + prisonRadius + frameSize;
    xPosition[2] = doorWidth / 2 - 1;
    xPosition[3] = -doorWidth / 2 + unitWidth[3] / 2;
    xPosition[4] = xPosition[5] = 0;
  } else if (door == "curved") {
    unitWidth[2] = unitWidth[4] = doorWidth / 2 + curvedRadius + frameSize;

    unitHeight[3] = doorHeightn + 2 * curvedRadius - 3.4 + frameSize;

    xPosition[0] = curvedRadius;
    xPosition[1] = -curvedRadius;
    xPosition[2] = -doorWidth / 2 + unitWidth[2] / 2 - frameSize;
    xPosition[3] = 0;
    xPosition[4] = doorWidth / 2 - unitWidth[4] / 2 + frameSize;
    xPosition[5] = -curvedRadius;
    xPosition[6] = curvedRadius;
    xPosition[7] = doorWidth / 2 - unitWidth[2] / 2 + frameSize / 2;
    xPosition[8] = -doorWidth / 2 + unitWidth[2] / 2 - frameSize / 2;

    yPosition[0] = yPosition[5] = doorHeightn / 2 + curvedRadius - 1.7;
    yPosition[1] = yPosition[6] = -doorHeightn / 2 - curvedRadius + 1.7;
    yPosition[2] = doorHeightn / 2 - 1.7;
    yPosition[3] = 0;
    yPosition[4] = -doorHeightn / 2 + 1.7;
  } else if (door == "lindsey") {
    unitHeight[2] = unitHeight[3] =
      doorHeightn + 2 * curvedRadius - (3 * frameSize) / 2;

    xPosition[0] = 0;
    xPosition[1] = 0;
    xPosition[2] = curvedRadius;
    xPosition[3] = -curvedRadius;
    xPosition[4] = xPosition[5] = -doorWidth / 2 - frameSize / 2;
    xPosition[6] = xPosition[7] = doorWidth / 2 + frameSize / 2;
    xPosition[8] = -doorWidth / 2 - curvedRadius - frameSize / 2;
    xPosition[9] = doorWidth / 2 + curvedRadius + frameSize / 2;

    yPosition[0] = doorHeightn / 2 + curvedRadius;
    yPosition[1] = -(doorHeightn / 2 + curvedRadius) + frameSize * 2;
    yPosition[2] = frameSize;
    yPosition[3] = yPosition[8] = yPosition[9] = frameSize;
    yPosition[4] = yPosition[6] = doorHeightn / 2 + curvedRadius;
    yPosition[5] = yPosition[7] =
      -doorHeightn / 2 - curvedRadius + frameSize * 2;
  } else if (door == "baku") {
    unitHeight[3] = doorHeightn + bakuRadius;

    xPosition[0] = xPosition[1] = xPosition[2] = xPosition[3] = 0;

    yPosition[0] = yPosition[1] = doorHeightn / 2 + bakuRadius;
    yPosition[2] = yPosition[1] - 1.2;
    yPosition[3] = -doorHeightn / 2 + unitHeight[3] / 2;
  } else if (door == "supreme") {
    unitHeight[3] = doorHeightn + bakuRadius;

    xPosition[0] =xPosition[3] = -doorWidth / 2;
    xPosition[1] =xPosition[4] = doorWidth / 2;

    xPosition[2] = 0;

    yPosition[0] = yPosition[1] = doorHeightn / 2 + bakuRadius;
    yPosition[2] = -doorHeightn / 2 + unitHeight[3] / 2;
    yPosition[3] = yPosition[3] = -doorHeightn / 2 - supremeRadius;
  } else if (door == "ultimate") {
    unitHeight[1] = 2 * doorHeightn / 3 + 4 * frameSize;
    unitHeight[10] = 2 * doorHeightn / 3 + ultimate2Radius;
    unitHeight[11] = doorHeightn / 3 + ultimate3Radius;

    unitWidth[2] = doorWidth / 6;
    unitWidth[3] = doorWidth / 3;
    unitWidth[6] = unitWidth[7] = doorWidth / 6 + 4 * frameSize;
    unitWidth[12] = doorWidth / 4;

    yPosition[0] = -doorHeightn / 2 + unitHeight[1];
    yPosition[1] = -doorHeightn / 2 + unitHeight[1] / 2;
    yPosition[2] = yPosition[4] = yPosition[6] = doorHeightn / 2 - doorHeightn / 3;
    yPosition[3] = yPosition[5] = -doorHeightn / 2 + doorHeightn / 3;
    yPosition[8] = doorHeightn / 2 - doorHeightn / 3 + ultimate2Radius;
    yPosition[9] = -doorHeightn / 2 + doorHeightn / 3 + ultimate3Radius;
    yPosition[10] = -doorHeightn / 2 + unitHeight[10] / 2;
    yPosition[11] = -doorHeightn / 2 + unitHeight[11] / 2;
    yPosition[12] = -doorHeightn / 2 + unitHeight[1] - ultimate1Radius;

    xPosition[0] = -doorWidth / 2 + doorWidth / 4 - ultimate1Radius;
    xPosition[1] = -doorWidth / 2 + doorWidth / 4;
    xPosition[2] = doorWidth / 2 - 1 - ultimate1Radius;
    xPosition[3] = -doorWidth / 2 + unitWidth[3] / 2;
    xPosition[4] = xPosition[5] = 0;
    xPosition[6] = xPosition[7] = -doorWidth / 2  + unitWidth[6] / 2;
    xPosition[8] = xPosition[9] = -doorWidth / 2 + unitWidth[6];
    xPosition[10] = -doorWidth / 2 + unitWidth[6] - ultimate2Radius;
    xPosition[11] = -doorWidth / 2 + unitWidth[6] - ultimate3Radius;
    xPosition[12] = doorWidth / 2 - unitWidth[12] / 2;
  } else if (door == "fisherman") {
    xPosition[0] = xPosition[1] = 0;
    xPosition[2] = -doorWidth / 2;
    xPosition[3] = -doorWidth / 2;
    
    yPosition[0] = 0;
    yPosition[1] = -doorWidth / Math.tan(fisherAngle)
    yPosition[2] = fishermanRadius;
    yPosition[3] = doorHeightn / 2 -doorWidth / Math.tan(fisherAngle)
  } else if (door == "primier") {
    unitHeight[1] = doorHeightn + kasteelRadius - 1.2;

    const radianDiff =
      -kasteelRadius + 1.2 - doorHeightn / 3 > 0
        ? (-kasteelRadius + 1.2 - doorHeightn / 3) / 4.5
        : 0;

    unitWidth[2] = doorWidth + frameSize;
    unitWidth[3] = doorWidth + frameSize;

    yPosition[0] = doorHeightn / 2 + kasteelRadius - 1.2;
    yPosition[1] = doorHeightn / 2 - unitHeight[1] / 2 + kasteelRadius - 1.2;
    yPosition[2] = yPosition[4] = doorHeightn / 2 - doorHeightn / 3;
    yPosition[3] = yPosition[5] = -doorHeightn / 2 + doorHeightn / 3;

    xPosition[1] = doorWidth / 2 + kasteelRadius + frameSize;
    xPosition[2] = -doorWidth / 2 + unitWidth[2] / 2 - frameSize / 2;
    xPosition[3] = -doorWidth / 2 + unitWidth[3] / 2 - frameSize / 2;
    xPosition[4] = xPosition[5] = 0;
  } else if (door == "elite") {
    unitWidth[0] = doorWidth - 1.6;
    unitWidth[3] = unitWidth[5] = unitWidth[0] - 1.6 + frameSize / 2;

    unitHeight[1] = doorHeightn / 2 - 1.2;
    unitHeight[4] = unitHeight[5] = unitHeight[1] - 2 + frameSize;
    unitHeight[8] = unitHeight[1] - 2;

    xPosition[0] = 0;
    xPosition[1] = -doorWidth / 2 + 0.8;
    xPosition[2] = doorWidth / 2 + frameSize / 2;
    xPosition[3] = xPosition[5] = -doorWidth / 2 + 0.8 + unitWidth[3] / 2 - frameSize / 2;
    xPosition[4] = unitWidth[0] / 2;
    xPosition[10] = doorWidth / 2 - 0.8 + 0.8 + frameSize;
    xPosition[11] = doorWidth / 2 + frameSize;

    yPosition[0] = doorHeightn / 2 - 0.8;
    yPosition[1] = doorHeightn / 2 - unitHeight[1] / 2 - 0.8 + frameSize / 2;
    yPosition[2] = -frameSize / 2;
    yPosition[3] = 0.4;
    yPosition[4] = 2 + unitHeight[4] / 2 + 0.4 - frameSize / 2;
    yPosition[5] = -0.4;
    yPosition[6] = -doorHeightn / 2 + unitHeight[1] / 2 + 0.8 + frameSize / 2;
    yPosition[7] = -doorHeightn / 2 + 0.8 + frameSize;
    yPosition[8] = -doorHeightn / 2 + unitHeight[8] / 2 + 0.8 + frameSize / 2;
    yPosition[10] = 0;
    yPosition[11] = 0;
  }

  const dt10Or13Path = new CustomCircleCurve(dt10Or13Radius, segments, 1);

  const contemperaRoundness = new CustomCircleCurve(
    dt10Or13Radius / 3,
    segments,
    1
  );

  return (
    <>
      <mesh
        position={[-doorWidth / 2 - frameSize, 0, 0]}
        castShadow
        receiveShadow
      >
        <boxGeometry args={[frameSize * 1.5, frameHeight, frameDepth]} />
        <meshBasicMaterial map={texture} castShadow receiveShadow />
      </mesh>

      {/* Right Frame */}
      <mesh
        position={[doorWidth / 2 + frameSize, 0, 0]}
        castShadow
        receiveShadow
      >
        <boxGeometry args={[1.5 * frameSize, frameHeight, frameDepth]} />
        <meshBasicMaterial map={texture} castShadow receiveShadow />
      </mesh>

      {/* Top Frame */}
      <mesh
        position={[0, doorHeightn / 2 + frameSize / 2, 0]}
        castShadow
        receiveShadow
      >
        <boxGeometry
          args={[doorWidth + 3 * frameSize, frameSize * 1.5, frameDepth]}
          castShadow
          receiveShadow
        />
        <meshBasicMaterial map={texture} />
      </mesh>

      {/* Bottom Frame */}
      <mesh
        position={[0, -doorHeightn / 2 - frameSize, 0]}
        castShadow
        receiveShadow
      >
        <boxGeometry
          args={[doorWidth + 3 * frameSize, 6 * frameSize, frameDepth]}
        />
        <meshBasicMaterial map={texture} />
      </mesh>

      {/* Door */}
      <mesh
        position={[
          0,
          0,
          isRotated
            ? -(frameDepth / 2 + doorDepth / 2 - 0.05)
            : frameDepth / 2 + doorDepth / 2 - 0.05,
        ]}
        castShadow
        receiveShadow
      >
        <boxGeometry
          args={[
            doorWidth + 2 * frameSize,
            doorHeightn + 2 * frameSize,
            doorDepth,
          ]}
        />
        <meshPhysicalMaterial
          color={glassType}
          transparent
          opacity={
            glassType == 0x111111
              ? 0.3
              : glassType == 0xfaf7f0
              ? 0.6
              : glassType == 0x060909
              ? 0.6
              : glassType == 0x98795d
              ? 0.7
              : glassType == 0xffffff
              ? 0.95
              : 0.8
          }
          roughness={0.11}
          metalness={0.5}
          clearcoat={1}
        />
      </mesh>
      <group>
        {(door == "rembrandt" ||
          door == "karel" ||
          door == "herenhuis" ||
          door == "elody" ||
          door == "boerderij" ||
          door == "dt2") && (
          <mesh
            position={[-doorWidth / positioner2, 0, 0]}
            castShadow
            receiveShadow
          >
            <boxGeometry args={[frameSize, interframeHeight2, frameDepth]} />
            <meshBasicMaterial map={texture} castShadow receiveShadow />
          </mesh>
        )}
        {door == "3pannel" && (
          <mesh
            position={[-doorWidth / positioner2, 0, 0]}
            castShadow
            receiveShadow
          >
            <boxGeometry
              args={[frameSize, (2 * doorHeightn) / positioner, frameDepth]}
            />
            <meshBasicMaterial map={texture} castShadow receiveShadow />
          </mesh>
        )}
        {door == "drentse" && (
          <>
            <mesh
              position={[0, doorHeightn / (positioner + 2), 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[interframeWidth, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} castShadow receiveShadow />
            </mesh>
            <mesh
              position={[-doorWidth / positioner2, 0, 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry
                args={[frameSize, (2 * doorHeightn) / positioner, frameDepth]}
                castShadow
                receiveShadow
              />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}

        {door == "3panel" && (
          <>
            <mesh
              position={[0, doorHeightn / 2 - doorHeightn / 3, 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[interframeWidth, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[0, -(doorHeightn / 2 - doorHeightn / 3), 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[interframeWidth, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}

        {(door == "herman" ||
          door == "lucas" ||
          door == "karel" ||
          door == "herenhuis" ||
          door == "elody" ||
          door == "3pannel" ||
          door == "drentse" ||
          door == "4panel" ||
          door == "boerderij" ||
          door == "dt3" ||
          door == "dt2" ||
          door == "parallel" ||
          door == "notaris" ||
          door == "dt11") && (
          <>
            {door == "parallel" || door == "notaris" ? (
              !isSide && (
                <mesh
                  position={[
                    0,
                    door == "parallel"
                      ? doorHeightn / positioner + frameSize / 5
                      : doorHeightn / 2 - (doorWidth - interframeWidth) / 2,
                    0,
                  ]}
                >
                  <boxGeometry
                    args={[interframeWidth, frameSize, frameDepth]}
                  />
                  <meshBasicMaterial map={texture} castShadow receiveShadow />
                </mesh>
              )
            ) : (
              <mesh
                position={[0, doorHeightn / positioner, 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[interframeWidth, frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
            )}
            {door != "dt2" &&
              (door == "parallel" || door == "notaris" ? (
                !isSide && (
                  <mesh
                    position={[
                      0,
                      door == "parallel"
                        ? -doorHeightn / positioner - frameSize / 5
                        : -(
                            doorHeightn / 2 -
                            (doorWidth - interframeWidth) / 2
                          ),
                      0,
                    ]}
                    castShadow
                    receiveShadow
                  >
                    <boxGeometry
                      args={[interframeWidth, frameSize, frameDepth]}
                    />
                    <meshBasicMaterial map={texture} />
                  </mesh>
                )
              ) : (
                <mesh
                  position={[0, -doorHeightn / positioner, 0]}
                  castShadow
                  receiveShadow
                >
                  <boxGeometry
                    args={[interframeWidth, frameSize, frameDepth]}
                  />
                  <meshBasicMaterial map={texture} />
                </mesh>
              ))}
          </>
        )}
        {(door == "4panel" ||
          door == "boerderij" ||
          door == "govert" ||
          door == "rembrandt" ||
          door == "2panel") && (
          <>
            <mesh
              position={[0, doorHeightn / positioner2, 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[interframeWidth, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {door == "dt3" && (
          <>
            <mesh
              position={[0, doorHeightn / positioner2, 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[interframeWidth, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[0, -doorHeightn / positioner2, 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[interframeWidth, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {door == "annelot" && (
          <>
            {!isSide && (
              <mesh
                position={[-doorWidth / positioner2, 0, 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry
                  args={[frameSize, interframeHeight2, frameDepth]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
            )}

            <mesh position={[0, yPosition[1], 0]} castShadow receiveShadow>
              <boxGeometry args={[interframeWidth, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[
                0,
                isSide
                  ? yPosition[1] + (width * 0.06) / positioner2
                  : yPosition[2],
                0,
              ]}
            >
              <boxGeometry args={[interframeWidth, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {door == "low-deep" && (
          <>
            <mesh
              position={[doorWidth / positioner2, 0, 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, interframeHeight2, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[0, doorHeightn / (positioner2 + 1), 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[interframeWidth, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {door == "staldeuren" && (
          <>
            <mesh position={[0, yPosition[0], 0]} castShadow receiveShadow>
              <boxGeometry args={[interframeWidth, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh position={[0, yPosition[1], 0]} castShadow receiveShadow>
              <boxGeometry args={[interframeWidth, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh position={[0, yPosition[2], 0]} castShadow receiveShadow>
              <boxGeometry args={[interframeWidth, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh position={[0, yPosition[3], 0]} castShadow receiveShadow>
              <boxGeometry
                args={[interframeWidth, unitHeight[3], frameDepth]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh position={[0, 0, 0]} castShadow receiveShadow>
              <boxGeometry args={[frameSize, doorHeightn, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {(door == "parallel" || door == "notaris") && (
          <>
            {door != "notaris" && !isSide && (
              <>
              <mesh
                position={[interframeWidth / 2, 0, 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry
                  args={[frameSize, interframeHeight2, frameDepth]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                  position={[
                    doorWidth / 2 - doorWidth / 8 + (3 * frameSize) / 2,
                    0,
                    0,
                  ]}
                  castShadow
                receiveShadow
                >
                  <boxGeometry args={[doorWidth / 4, frameSize, frameDepth]} />
                  <meshBasicMaterial map={texture} />
                </mesh>
                <mesh
                  position={[
                    -doorWidth / 2 + doorWidth / 8 - (3 * frameSize) / 2,
                    0,
                    0,
                  ]}
                  castShadow
                receiveShadow
                >
                  <boxGeometry args={[doorWidth / 4, frameSize, frameDepth]} />
                  <meshBasicMaterial map={texture} />
                </mesh>
              </>
            )}
            {!isSide && (
              <>
                <mesh
                  position={[-interframeWidth / 2, 0, 0]}
                  castShadow
                  receiveShadow
                >
                  <boxGeometry
                    args={[frameSize, interframeHeight2, frameDepth]}
                  />
                  <meshBasicMaterial map={texture} />
                </mesh>
                <mesh
                  position={[0, doorHeightn / 2 - interframeHeight3 / 2, 0]}
                  castShadow
                  receiveShadow
                >
                  <boxGeometry
                    args={[frameSize, interframeHeight3, frameDepth]}
                  />
                  <meshBasicMaterial map={texture} />
                </mesh>
                <mesh
                  position={[0, -doorHeightn / 2 + interframeHeight3 / 2, 0]}
                  castShadow
                  receiveShadow
                >
                  <boxGeometry
                    args={[frameSize, interframeHeight3, frameDepth]}
                  />
                  <meshBasicMaterial map={texture} />
                </mesh>
              </>
            )}
            {door == "notaris" && !isSide ? (
              <>
                <mesh
                  position={[
                    doorWidth / 2 + frameSize,
                    0,
                    isRotated ? -0.1 : 0.1,
                  ]}
                  rotation={[0, 0, Math.PI / 2]}
                  castShadow
                  receiveShadow
                >
                  <extrudeGeometry args={[halfCircleShape, extrudeSettings]} />
                  <meshBasicMaterial map={texture} />
                </mesh>
                <mesh
                  position={[
                    -(
                      interframeWidth / 2 +
                      (doorWidth / 2 - interframeWidth / 2) / 2
                    ),
                    0,
                    0,
                  ]}
                >
                  <boxGeometry
                    args={[
                      doorWidth / 2 - interframeWidth / 2 + frameSize,
                      frameSize,
                      frameDepth,
                    ]}
                  />
                  <meshBasicMaterial map={texture} />
                </mesh>
                <mesh
                  position={[interframeWidth / 2 - frameSize, 0, 0]}
                  castShadow
                  receiveShadow
                >
                  <boxGeometry args={[radiusn / 2, frameSize, frameDepth]} />
                  <meshBasicMaterial map={texture} />
                </mesh>
                <mesh
                  position={[
                    interframeWidth / 2 - interframeHeight / 2 - frameSize / 4,
                    doorHeightn / 2 -
                      interframeHeight2 / 2.5 / 2 -
                      (doorWidth - interframeWidth) / 2 -
                      frameSize / 4,
                    0,
                  ]}
                >
                  <boxGeometry
                    args={[frameSize, interframeHeight2 / 2.45, frameDepth]}
                  />
                  <meshBasicMaterial map={texture} />
                </mesh>
                <mesh
                  position={[
                    interframeWidth / 2 - interframeHeight / 2 - frameSize / 4,
                    -(
                      doorHeightn / 2 -
                      interframeHeight2 / 2.5 / 2 -
                      (doorWidth - interframeWidth) / 2 -
                      frameSize / 4
                    ),
                    0,
                  ]}
                >
                  <boxGeometry
                    args={[frameSize, interframeHeight2 / 2.45, frameDepth]}
                  />
                  <meshBasicMaterial map={texture} />
                </mesh>
                <mesh
                  position={[interframeWidth / 2 + 0.21 * radiusn, 0, 0]}
                  rotation={[0, 0, Math.PI / 1.55]}
                >
                  <tubeGeometry args={[path, 28, frameSize * 0.55, , false]} />
                  <meshBasicMaterial map={texture} />
                </mesh>
              </>
            ) : (
              isSide && (
                <mesh
                  position={[isSide ? 0 : dt8Radius / 2, 0, 0]}
                  castShadow
                  receiveShadow
                >
                  <boxGeometry
                    args={[doorWidth + frameSize, frameSize, frameDepth]}
                  />
                  <meshBasicMaterial map={texture} />
                </mesh>
              )
            )}
          </>
        )}
        {door == "toogPlus" && !isSide && (
          <>
            <mesh
              position={[
                dt7Radius + doorWidth / 2 + frameSize,
                -interframeHeight2 * 0.21,
                0,
              ]}
            >
              <boxGeometry
                args={[frameSize, interframeHeight2 * 0.58, frameDepth]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[
                doorWidth / 2 + frameSize,
                (-interframeHeight2 / doorHeightn) * -0.8,
                0,
              ]}
              rotation={[0, 0, -4.7]}
            >
              <tubeGeometry args={[dt7Path, 28, frameSize * 0.56, 8, false]} />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {door == "larino2" && (
          <>
            <mesh position={[xPosition[0], 0, 0]} castShadow receiveShadow>
              <boxGeometry args={[frameSize, interframeHeight2, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[1], yPosition[1], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[unitWidth[1], unitHeight[1], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {door == "fabric" && (
          <>
            <mesh position={[0, yPosition[0], 0]} castShadow receiveShadow>
              <boxGeometry args={[interframeWidth, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh position={[0, yPosition[1], 0]} castShadow receiveShadow>
              <boxGeometry args={[interframeWidth, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh position={[0, yPosition[3], 0]} castShadow receiveShadow>
              <boxGeometry args={[frameSize, unitHeight[3], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh position={[0, yPosition[4], 0]} castShadow receiveShadow>
              <boxGeometry args={[frameSize, unitHeight[4], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {door == "larino" && (
          <>
            {!isSide && (
              <mesh position={[0, 0, 0]} castShadow receiveShadow>
                <boxGeometry args={[frameSize, doorHeightn, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
            )}
            <mesh
              position={[isSide ? 0 : -unitWidth[0] / 2, 0, 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry
                args={[
                  isSide ? interframeWidth : unitWidth[0],
                  frameSize,
                  frameDepth,
                ]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {door == "toog" && (
          <>
            <mesh
              position={[-xPosition[0], yPosition[0] - frameSize / 2, 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[0], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[-xPosition[1], yPosition[1] - frameSize / 2, 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[1], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[0, yPosition[2] - frameSize / 2, 0]}
              rotation={[0, 0, Math.PI * 2]}
            >
              <tubeGeometry
                args={[dt10Or13Path, 28, frameSize * 0.57, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {door == "contempera" && !isSide && (
          <>
            <mesh
              position={[xPosition[0], yPosition[0], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[0], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[1], yPosition[1], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[1], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[2], yPosition[2], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[2], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[3], yPosition[3], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[3], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[-xPosition[1], yPosition[1] - frameSize / 2, 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[1], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[4], yPosition[4], 0]}
              rotation={[0, 0, Math.PI * 2]}
            >
              <tubeGeometry
                args={[dt10Or13Path, 28, frameSize * 0.57, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[4], yPosition[12], 0]}
              rotation={[0, 0, Math.PI * 2]}
            >
              <tubeGeometry
                args={[contemperaRoundness, 28, frameSize * 0.57, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[5], yPosition[5], 0]}
              rotation={[0, 0, Math.PI * 2]}
            >
              <tubeGeometry
                args={[dt10Or13Path, 28, frameSize * 0.57, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[5], yPosition[12], 0]}
              rotation={[0, 0, Math.PI * 2]}
            >
              <tubeGeometry
                args={[contemperaRoundness, 28, frameSize * 0.57, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[6], yPosition[6], 0]}
              rotation={[0, 0, Math.PI]}
            >
              <tubeGeometry
                args={[dt10Or13Path, 28, frameSize * 0.57, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[6], yPosition[13], 0]}
              rotation={[0, 0, Math.PI]}
            >
              <tubeGeometry
                args={[contemperaRoundness, 28, frameSize * 0.57, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[7], yPosition[7], 0]}
              rotation={[0, 0, Math.PI]}
            >
              <tubeGeometry
                args={[dt10Or13Path, 28, frameSize * 0.57, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[7], yPosition[13], 0]}
              rotation={[0, 0, Math.PI]}
            >
              <tubeGeometry
                args={[contemperaRoundness, 28, frameSize * 0.57, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[8], yPosition[8], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[8], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[9], yPosition[9], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[9], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[10], yPosition[10], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[10], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[11], yPosition[11], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[11], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {door == "grandma" && (
          <>
            <mesh position={[0, yPosition[0], 0]} castShadow receiveShadow>
              <boxGeometry args={[interframeWidth, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh position={[0, yPosition[1], 0]} castShadow receiveShadow>
              <boxGeometry args={[interframeWidth, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh position={[xPosition[2], 0, 0]} castShadow receiveShadow>
              <boxGeometry args={[frameSize, doorHeightn, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh position={[xPosition[3], 0, 0]} castShadow receiveShadow>
              <boxGeometry args={[frameSize, doorHeightn, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {door == "80s" && (
          <>
            <mesh position={[0, yPosition[0], 0]} castShadow receiveShadow>
              <boxGeometry args={[interframeWidth, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh position={[0, yPosition[1], 0]} castShadow receiveShadow>
              <boxGeometry args={[interframeWidth, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh position={[xPosition[2], 0, 0]} castShadow receiveShadow>
              <boxGeometry args={[frameSize, doorHeightn, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh position={[xPosition[3], 0, 0]} castShadow receiveShadow>
              <boxGeometry args={[frameSize, doorHeightn, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {(door == "rondPlus" || door == "rond") && (
          <>
            {door == "rondPlus" && (
              <mesh
                position={[isSide ? 0 : dt8Radius / 2, 0, 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry
                  args={[
                    isSide
                      ? interframeWidth
                      : interframeWidth + dt8Radius + frameSize * 0.5,
                    frameSize,
                    frameDepth,
                  ]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
            )}

            {!isSide && (
              <mesh
                position={[(-doorWidth / positioner2) * 2.1, 0, 0]}
                rotation={[0, 0, Math.PI / 2]}
              >
                <tubeGeometry
                  args={[dt8Path, 28, frameSize * 0.56, 8, false]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
            )}
          </>
        )}
        {door == "porto" && (
          <>
            {!isSide && (
              <>
                <mesh
                  position={[-xPosition[0] * 1.2, 0, 0]}
                  castShadow
                  receiveShadow
                >
                  <boxGeometry
                    args={[frameSize, interframeHeight2, frameDepth]}
                  />
                  <meshBasicMaterial map={texture} />
                </mesh>
                <mesh
                  position={[xPosition[0], yPosition[4], 0]}
                  castShadow
                  receiveShadow
                >
                  <boxGeometry
                    args={[frameSize, unitHeight[4], frameDepth]}
                    castShadow
                    receiveShadow
                  />
                  <meshBasicMaterial map={texture} />
                </mesh>
                <mesh
                  position={[xPosition[3], yPosition[3], 0]}
                  castShadow
                  receiveShadow
                >
                  <boxGeometry args={[frameSize, unitHeight[3], frameDepth]} />
                  <meshBasicMaterial map={texture} />
                </mesh>
                <mesh
                  position={[xPosition[5], yPosition[5], 0]}
                  rotation={[0, 2 * Math.PI, 0]}
                  castShadow
                  receiveShadow
                >
                  <tubeGeometry
                    args={[dt9Path, 28, frameSize * 0.57, 8, false]}
                  />
                  <meshBasicMaterial map={texture} />
                </mesh>
                <mesh
                  position={[xPosition[6], yPosition[6], 0]}
                  rotation={[0, 2 * Math.PI, 0]}
                >
                  <tubeGeometry
                    args={[dt9Path, 28, frameSize * 0.58, 8, false]}
                  />
                  <meshBasicMaterial map={texture} />
                </mesh>
              </>
            )}
            <mesh
              position={[
                isSide ? 0 : -xPosition[1],
                isSide
                  ? doorHeightn / 2 -
                    Math.abs((width * 0.06 + frameSize) / positioner2)
                  : yPosition[1],
                0,
              ]}
            >
              <boxGeometry
                args={[
                  isSide ? interframeWidth : unitWidth[1] - frameSize,
                  frameSize,
                  frameDepth,
                ]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[
                isSide ? 0 : xPosition[2],
                isSide
                  ? -Math.abs((width * 0.06 + frameSize) / positioner2) / 2
                  : -xPosition[0] / 2,
                0,
              ]}
            >
              <boxGeometry
                args={[
                  isSide ? interframeWidth : unitWidth[2],
                  frameSize,
                  frameDepth,
                ]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {door == "rivera" && (
          <>
            <mesh
              position={[-xPosition[0], yPosition[0] - frameSize / 2, 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[0], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[-xPosition[1], yPosition[1] - frameSize / 2, 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[1], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[-xPosition[2], yPosition[2], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[2], frameSize]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[-xPosition[3], yPosition[3], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[3], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[0, yPosition[5] - frameSize / 2, 0]}
              rotation={[0, 0, Math.PI * 2]}
              castShadow
              receiveShadow
            >
              <tubeGeometry
                args={[dt10Or13Path, 28, frameSize * 0.57, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[0, yPosition[6], 0]}
              rotation={[0, 0, Math.PI]}
              castShadow
              receiveShadow
            >
              <tubeGeometry
                args={[dt10Or13Path, 28, frameSize * 0.57, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {door == "dt13" && (
          <>
            {!isSide && (
              <>
                <mesh
                  position={[-xPosition[0], yPosition[0], 0]}
                  castShadow
                  receiveShadow
                >
                  <boxGeometry args={[frameSize, unitHeight[0], frameDepth]} />
                  <meshBasicMaterial map={texture} />
                </mesh>
                <mesh
                  position={[-xPosition[1], yPosition[1], 0]}
                  castShadow
                  receiveShadow
                >
                  <boxGeometry args={[frameSize, unitHeight[1], frameDepth]} />
                  <meshBasicMaterial map={texture} />
                </mesh>
                <mesh
                  position={[0, yPosition[2], 0]}
                  rotation={[0, 0, Math.PI * 2]}
                  castShadow
                  receiveShadow
                >
                  <tubeGeometry
                    args={[dt10Or13Path, 28, frameSize * 0.57, 8, false]}
                  />
                  <meshBasicMaterial map={texture} />
                </mesh>
                <mesh
                  position={[0, yPosition[3], 0]}
                  rotation={[0, 0, Math.PI]}
                >
                  <tubeGeometry
                    args={[dt10Or13Path, 28, frameSize * 0.57, 8, false]}
                  />
                  <meshBasicMaterial map={texture} />
                </mesh>
              </>
            )}

            <mesh
              position={[isSide ? 0 : -xPosition[4], yPosition[4], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry
                args={[
                  isSide ? interframeWidth : unitWidth[4],
                  frameSize,
                  frameDepth,
                ]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[isSide ? 0 : -xPosition[5], yPosition[5], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry
                args={[
                  isSide ? interframeWidth : unitWidth[5],
                  frameSize,
                  frameDepth,
                ]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {(door == "dt11" || door == "boender") && (
          <>
            <mesh
              position={[
                xPosition[0],
                door == "dt11" ? interframeHeight2 * 0.11 : 0,
                0,
              ]}
            >
              <boxGeometry
                args={[
                  frameSize,
                  door == "dt11"
                    ? interframeHeight2 / 1.25 - frameSize
                    : interframeHeight2,
                  frameSize,
                ]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[
                -xPosition[0],
                door == "dt11" ? interframeHeight2 * -0.11 : 0,
                0,
              ]}
            >
              <boxGeometry
                args={[
                  frameSize,
                  door == "dt11"
                    ? interframeHeight2 / 1.25 - frameSize
                    : interframeHeight2,
                  frameDepth,
                ]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {door == "boender" && (
          <>
            {/* left col */}
            <mesh
              position={[xPosition[1], yPosition[1], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[unitWidth[0], frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh position={[xPosition[1], 0, 0]} castShadow receiveShadow>
              <boxGeometry args={[unitWidth[0], frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            {/* Middle col */}
            <mesh position={[0, yPosition[1] / 2, 0]} castShadow receiveShadow>
              <boxGeometry args={[unitWidth[0], frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh position={[0, -yPosition[1] / 2, 0]} castShadow receiveShadow>
              <boxGeometry args={[unitWidth[0], frameSize, frameSize]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[0, (-4 * yPosition[1]) / 3, 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[unitWidth[0], frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            {/* Right col */}
            <mesh
              position={[-xPosition[1], yPosition[1], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[unitWidth[0], frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[-xPosition[1], -yPosition[1], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[unitWidth[0], frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {door == "kasteel" &&
          (isSide ? (
            <mesh
              position={[xPosition[4], yPosition[4], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[doorWidth, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
          ) : (
            <>
              <mesh
                position={[doorWidth / 2 + frameSize, yPosition[0], 0]}
                rotation={[0, 0, -4.7]}
              >
                <tubeGeometry
                  args={[kasteelPath, 28, frameSize * 0.56, 8, false]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[1], yPosition[1], 0]}
                rotation={[0, 0, 4.7]}
              >
                <tubeGeometry
                  args={[kasteelPath, 28, frameSize * 0.56, 8, false]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[2], yPosition[2], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[frameSize, unitHeight[2], frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[3], yPosition[3], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[frameSize, unitHeight[3], frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
            </>
          ))}
        {door == "kathedraal" &&
          (isSide ? (
            <>
              <mesh
                position={[xPosition[4], yPosition[4], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[doorWidth, frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[5], yPosition[5], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[doorWidth, frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
            </>
          ) : (
            <>
              <mesh
                position={[doorWidth / 2 + frameSize, yPosition[0], 0]}
                rotation={[0, 0, -4.7]}
              >
                <tubeGeometry
                  args={[kasteelPath, 28, frameSize * 0.56, 8, false]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[1], yPosition[1], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[frameSize, unitHeight[1], frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[2], yPosition[2], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[2], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[3], yPosition[3], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[3], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
            </>
          ))}
        {door == "gladiator" &&
          (isSide ? (
            <>
              <mesh
                position={[-(doorWidth / positioner) * 1.78, yPosition[0], 0]}
                rotation={[0, 0, 0]}
              >
                <tubeGeometry
                  args={[kasteelPath, 28, frameSize * 0.56, 8, false]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[4], yPosition[4], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[frameSize, unitHeight[1], frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[5], yPosition[2], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[2], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[6], yPosition[3], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[3], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
            </>
          ) : (
            <>
              <mesh
                position={[(doorWidth / positioner) * 1.78, yPosition[0], 0]}
                rotation={[0, 0, -4.7]}
              >
                <tubeGeometry
                  args={[kasteelPath, 28, frameSize * 0.56, 8, false]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[1], yPosition[1], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[frameSize, unitHeight[1], frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[2], yPosition[2], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[2], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[3], yPosition[3], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[3], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
            </>
          ))}
        {door == "the-judge" && !isSide && (
          <>
            <mesh
              position={[xPosition[0], yPosition[0], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, doorHeightn, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[1], yPosition[1], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[unitWidth[1], unitHeight[1], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {door == "prison" &&
          (isSide ? (
            <>
              <mesh
                position={[xPosition[4], yPosition[4], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[doorWidth, frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[5], yPosition[5], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[doorWidth, frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
            </>
          ) : (
            <>
              <mesh
                position={[doorWidth / 2 + frameSize, yPosition[0], 0]}
                rotation={[0, 0, -4.7]}
              >
                <tubeGeometry
                  args={[prisonPath, 28, frameSize * 0.56, 8, false]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[1], yPosition[1], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[frameSize, unitHeight[1], frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh position={[xPosition[2], 0, 0]} castShadow receiveShadow>
                <boxGeometry args={[frameSize, doorHeightn, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
            </>
          ))}
        {door == "curved" &&
          (isSide ? (
            <>
              <mesh
                position={[xPosition[5], yPosition[5], 0]}
                rotation={[0, 0, -4.7]}
              >
                <tubeGeometry
                  args={[curvedPath, 28, frameSize * 0.56, 8, false]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[6], yPosition[6], 0]}
                rotation={[0, 0, 4.7]}
              >
                <tubeGeometry
                  args={[curvedPath, 28, frameSize * 0.56, 8, false]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[7], yPosition[2], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[2], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[3], yPosition[3], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[frameSize, unitHeight[3], frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[8], yPosition[4], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[4], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
            </>
          ) : (
            <>
              <mesh
                position={[xPosition[0], yPosition[0], 0]}
                rotation={[0, 0, 0]}
              >
                <tubeGeometry
                  args={[curvedPath, 28, frameSize * 0.56, 8, false]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[1], yPosition[1], 0]}
                rotation={[0, Math.PI, 4.7]}
              >
                <tubeGeometry
                  args={[curvedPath, 28, frameSize * 0.56, 8, false]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[2], yPosition[2], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[2], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[3], yPosition[3], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[frameSize, unitHeight[3], frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[4], yPosition[4], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[4], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
            </>
          ))}

        {door == "lindsey" && (
          <>
            <mesh
              position={[xPosition[0], yPosition[0], 0]}
              rotation={[0, 0, 0]}
            >
              <tubeGeometry
                args={[curvedPath2, 28, frameSize * 0.56, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[1], yPosition[1], 0]}
              rotation={[Math.PI, 0, 0]}
            >
              <tubeGeometry
                args={[curvedPath2, 28, frameSize * 0.56, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[2], yPosition[2], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[2], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[3], yPosition[3], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[3], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[4], yPosition[4], 0]}
              rotation={[0, 0, 0]}
            >
              <tubeGeometry
                args={[curvedPath, 28, frameSize * 0.56, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[5], yPosition[5], 0]}
              rotation={[Math.PI, 0, 0]}
            >
              <tubeGeometry
                args={[curvedPath, 28, frameSize * 0.56, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[6], yPosition[6], 0]}
              rotation={[0, Math.PI, 0]}
            >
              <tubeGeometry
                args={[curvedPath, 28, frameSize * 0.56, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[7], yPosition[7], 0]}
              rotation={[Math.PI, Math.PI, 0]}
            >
              <tubeGeometry
                args={[curvedPath, 28, frameSize * 0.56, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[8], yPosition[8], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[3], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[9], yPosition[9], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[3], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {door == "baku" && (
          <>
            <mesh
              position={[xPosition[0], yPosition[0], 0]}
              rotation={[0, 0, 0]}
            >
              <tubeGeometry
                args={[bakuPath2, 28, frameSize * 0.56, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[1], yPosition[1], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[doorWidth + frameSize, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[2], yPosition[2], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[doorWidth + frameSize, frameSize, frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[3], yPosition[3], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[3], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {door == "supreme" && (
          <>
            <mesh
              position={[xPosition[0], yPosition[0], 0]}
              rotation={[0, 0, 0]}
            >
              <tubeGeometry
                args={[supremePath, 28, frameSize * 0.56, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[1], yPosition[0], 0]}
              rotation={[0, 0, Math.PI / 2]}
            >
              <tubeGeometry
                args={[supremePath, 28, frameSize * 0.56, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[2], yPosition[2], 0]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[frameSize, unitHeight[3], frameDepth]} />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[3], yPosition[3], 0]}
              rotation={[0, 0, -Math.PI / 2]}
            >
              <tubeGeometry
                args={[supremePath2, 28, frameSize * 0.56, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
            <mesh
              position={[xPosition[4], yPosition[3], 0]}
              rotation={[0, 0, Math.PI / 2]}
            >
              <tubeGeometry
                args={[supremePath2, 28, frameSize * 0.56, 8, false]}
              />
              <meshBasicMaterial map={texture} />
            </mesh>
          </>
        )}
        {door == "ultimate" &&
          (isSide ? (
            <>
              <mesh
                position={[xPosition[4], yPosition[4], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[doorWidth, frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[5], yPosition[5], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[doorWidth, frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
            </>
          ) : (
            <>
              <mesh
                position={[xPosition[0], yPosition[0], 0]}
                rotation={[0, 0, -4.7]}
              >
                <tubeGeometry
                  args={[ultimate1Path, 28, frameSize * 0.56, 8, false]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[1], yPosition[1], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[frameSize, unitHeight[1], frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[6], yPosition[4], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[6], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[7], yPosition[5], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[7], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[8], yPosition[8], 0]}
                rotation={[0, 0, 0]}
              >
                <tubeGeometry
                  args={[ultimate2Path, 28, frameSize * 0.56, 8, false]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[9], yPosition[9], 0]}
                rotation={[0, 0, 0]}
              >
                <tubeGeometry
                  args={[ultimate3Path, 28, frameSize * 0.56, 8, false]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[10], yPosition[10], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[frameSize, unitHeight[10], frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[11], yPosition[11], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[frameSize, unitHeight[11], frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[12], yPosition[12], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[12], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
            </>
          ))}
          {door == "fisherman" &&
          (isSide ? (
            <>
              <mesh
                position={[xPosition[0], yPosition[0], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[doorWidth, frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[1], yPosition[1], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[doorWidth, frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
            </>
          ) : (
            <>
              <mesh
                position={[xPosition[2], yPosition[2], 0]}
                rotation={[0, 0, Math.PI * (90 - fisherAngle) / 180]}
              >
                <tubeGeometry
                  args={[fishermanPath, 28, frameSize * 0.56, 8, false]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[3], yPosition[3], 0]}
                rotation={[0, 0, -Math.PI / 2]}
              >
                <tubeGeometry
                  args={[fishermanPath, 28, frameSize * 0.56, 8, false]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
            </>
          ))}
          {door == "primier" &&
          (isSide ? (
            <>
              <mesh
                position={[xPosition[4], yPosition[4], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[doorWidth, frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[5], yPosition[5], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[doorWidth, frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
            </>
          ) : (
            <>
              <mesh
                position={[doorWidth / 2 + frameSize, yPosition[0], 0]}
                rotation={[0, 0, -4.7]}
              >
                <tubeGeometry
                  args={[kasteelPath, 28, frameSize * 0.56, 8, false]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[1], yPosition[1], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[frameSize, unitHeight[1], frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[2], yPosition[2], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[2], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[3], yPosition[3], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[3], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
            </>
          ))}
          {door == "elite" &&
          (isSide ? (
            <>
              <mesh
                position={[xPosition[0], yPosition[0], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[0], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[1], yPosition[1], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[frameSize, unitHeight[1], frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[0, yPosition[3], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[0] + frameSize, frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[4], yPosition[1], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[frameSize, unitHeight[1], frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[0, yPosition[5], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[0], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[1], yPosition[6], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[frameSize, unitHeight[1], frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[0], yPosition[7], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[0], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[4], yPosition[6], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[frameSize, unitHeight[1], frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
            </>
          ) : (
            <>
              <mesh
                position={[xPosition[0], yPosition[0], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[0], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[1], yPosition[1], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[frameSize, unitHeight[1], frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[2], yPosition[2], 0]}
                rotation={[0, 0, Math.PI * (eliteAngle2 + 90 + eliteAngle1 / 2) / 180]}
              >
                <tubeGeometry
                  args={[elite2Path, 28, frameSize * 0.56, 8, false]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[3], yPosition[3], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[3], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[4], yPosition[4], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[frameSize, unitHeight[4], frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[5], yPosition[5], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[5], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[1], yPosition[6], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[frameSize, unitHeight[1], frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[0], yPosition[7], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[unitWidth[0], frameSize, frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[4], yPosition[8], 0]}
                castShadow
                receiveShadow
              >
                <boxGeometry args={[frameSize, unitHeight[8], frameDepth]} />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[10], yPosition[10], 0]}
                rotation={[0, 0, Math.PI * (180 + eliteAngle2) / 180]}
              >
                <tubeGeometry
                  args={[elite2Path, 28, frameSize * 0.56, 8, false]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
              <mesh
                position={[xPosition[11], yPosition[11], 0]}
                rotation={[0, 0, Math.PI / 2]}
              >
                <tubeGeometry
                  args={[elite1Path2, 28, frameSize * 0.56, 8, false]}
                />
                <meshBasicMaterial map={texture} />
              </mesh>
            </>
          ))}
      </group>
    </>
  );
}
