import React from "react";

export default function TechInformation({techInformation}) {

  return (
    <>
      <div style={{ width: "100%" }}>
        <div
          style={{
            margin: "2% 5% 2% 5%",
            paddingTop: "0.5rem",
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "bolder",
            fontSize: "normal",
          }}
        >
          <span>Technische informatie</span>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <div
          style={{
            margin: "2% 5% 2% 5%",
            paddingTop: "0.5rem",
            fontWeight: "bolder",
            fontSize: "normal",
            borderBottom: "1px solid GrayText",
          }}
        >
          {/* <div>
            <span
              style={{
                fontSize: "small",
                color: "GrayText",
                fontWeight: "normal",
              }}
            >
              Type deur
            </span>
          </div> */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <span
                style={{
                  fontSize: "14px",
                  color: "GrayText",
                  fontWeight: "bold",
                }}
              >
                Deur type
              </span>
            </div>
            <span>{techInformation.type}</span>
            {/* <span>&euro; 0,00</span> */}
          </div>
        </div>
      </div>
      <div style={{ width: "100%", marginTop: "15px" }}>
        <div
          style={{
            margin: "2% 5% 2% 5%",
            paddingTop: "0.5rem",
            fontWeight: "bolder",
            fontSize: "normal",
            borderBottom: "1px solid GrayText",
          }}
        >
          {/* <div>
            <span
              style={{
                fontSize: "small",
                color: "GrayText",
                fontWeight: "normal",
              }}
            >
              Aantal deuren in offerte
            </span>
          </div> */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={{
                fontSize: "14px",
                color: "GrayText",
                fontWeight: "bold",
              }}
            >
              Samenstelling deur
            </span>
            <span>{techInformation.doorConfig}</span>
            {/* <span>&euro; 0,00</span> */}
          </div>
        </div>
      </div>
      <div style={{ width: "100%", marginTop: "15px" }}>
        <div
          style={{
            margin: "2% 5% 2% 5%",
            paddingTop: "0.5rem",
            fontWeight: "bolder",
            fontSize: "normal",
            borderBottom: "1px solid GrayText",
          }}
        >
          {/* <div>
            <span
              style={{
                fontSize: "small",
                color: "GrayText",
                fontWeight: "normal",
              }}
            >
              Aantal en plaats zijpanelen
            </span>
          </div> */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={{
                fontSize: "14px",
                color: "GrayText",
                fontWeight: "bold",
              }}
            >
              Samenstelling zijpaneel
            </span>
            <span>{techInformation.sidePannel}</span>
            {/* <span>&euro; 0,00</span> */}
          </div>
        </div>
      </div>
      <div style={{ width: "100%", marginTop: "15px" }}>
        <div
          style={{
            margin: "2% 5% 2% 5%",
            paddingTop: "0.5rem",
            fontWeight: "bolder",
            fontSize: "normal",
            borderBottom: "1px solid GrayText",
          }}
        >
          {/* <div>
            <span
              style={{
                fontSize: "small",
                color: "GrayText",
                fontWeight: "normal",
              }}
            >
              Verdeling elementen
            </span>
          </div> */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={{
                fontSize: "14px",
                color: "GrayText",
                fontWeight: "bold",
              }}
            >
              Paneel verdeling
            </span>
            <span>{techInformation.sidePannelConfig}</span>
            {/* <span>&euro; 0,00</span> */}
          </div>
        </div>
      </div>
      {techInformation.sidePannelConfig === "eigen maat" && (
        <div style={{ width: "100%", marginTop: "15px" }}>
          <div
            style={{
              margin: "2% 5% 2% 5%",
              paddingTop: "0.5rem",
              fontWeight: "bolder",
              fontSize: "normal",
              borderBottom: "1px solid GrayText",
            }}
          >
            {/* <div>
              <span
                style={{
                  fontSize: "small",
                  color: "GrayText",
                  fontWeight: "normal",
                }}
              >
                Breedte deur
              </span>
            </div> */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span
                style={{
                  fontSize: "14px",
                  color: "GrayText",
                  fontWeight: "bold",
                }}
              >
                Breedte deur
              </span>
              <span>{techInformation.sidePannelSize}</span>
              {/* <span>&euro; 0,00</span> */}
            </div>
          </div>
        </div>
      )}
      <div style={{ width: "100%", marginTop: "15px" }}>
        <div
          style={{
            margin: "2% 5% 2% 5%",
            paddingTop: "0.5rem",
            fontWeight: "bolder",
            fontSize: "normal",
            borderBottom: "1px solid GrayText",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={{
                fontSize: "14px",
                color: "GrayText",
                fontWeight: "bold",
              }}
            >
              Breedte deur
            </span>
            <span>
              {techInformation.width}
            </span>
            {/* <span>&euro; 0,00</span> */}
          </div>
        </div>
      </div>
      <div style={{ width: "100%", marginTop: "15px" }}>
        <div
          style={{
            margin: "2% 5% 2% 5%",
            paddingTop: "0.5rem",
            fontWeight: "bolder",
            fontSize: "normal",
            borderBottom: "1px solid GrayText",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={{
                fontSize: "14px",
                color: "GrayText",
                fontWeight: "bold",
              }}
            >
              Hoogte sparing
            </span>
            <span>{techInformation.height}</span>
            {/* <span>&euro; 0,00</span> */}
          </div>
        </div>
      </div>
      <div style={{ width: "100%", marginTop: "15px" }}>
        <div
          style={{
            margin: "2% 5% 2% 5%",
            paddingTop: "0.5rem",
            fontWeight: "bolder",
            fontSize: "normal",
            borderBottom: "1px solid GrayText",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={{
                fontSize: "14px",
                color: "GrayText",
                fontWeight: "bold",
              }}
            >
              Breedte sparing
            </span>
            <span>
              {techInformation.holeWidth}
            </span>
            {/* <span>&euro; 0,00</span> */}
          </div>
        </div>
      </div>
      <div style={{ width: "100%", marginTop: "15px" }}>
        <div
          style={{
            margin: "2% 5% 2% 5%",
            paddingTop: "0.5rem",
            fontWeight: "bolder",
            fontSize: "normal",
            borderBottom: "1px solid GrayText",
          }}
        >
          {/* <div>
            <span
              style={{
                fontSize: "small",
                color: "GrayText",
                fontWeight: "normal",
              }}
            >
              Vlakverdeling
            </span>
          </div> */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={{
                fontSize: "14px",
                color: "GrayText",
                fontWeight: "bold",
              }}
            >
              Design
            </span>
            <span>{techInformation.door}</span>
            {/* <span>&euro; 0,00</span> */}
          </div>
        </div>
      </div>
      <div style={{ width: "100%", marginTop: "15px" }}>
        <div
          style={{
            margin: "2% 5% 2% 5%",
            paddingTop: "0.5rem",
            fontWeight: "bolder",
            fontSize: "normal",
            borderBottom: "1px solid GrayText",
          }}
        >
          {/* <div>
            <span
              style={{
                fontSize: "small",
                color: "GrayText",
                fontWeight: "normal",
              }}
            >
              Handgreep
            </span>
          </div> */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={{
                fontSize: "14px",
                color: "GrayText",
                fontWeight: "bold",
              }}
            >
              Handgreep
            </span>
            <span>{techInformation.handle}</span>
            {/* <span>&euro; 0,00</span> */}
          </div>
        </div>
      </div>
      <div style={{ width: "100%", marginTop: "15px" }}>
        <div
          style={{
            margin: "2% 5% 2% 5%",
            paddingTop: "0.5rem",
            fontWeight: "bolder",
            fontSize: "normal",
            borderBottom: "1px solid GrayText",
          }}
        >
          {/* <div>
            <span
              style={{
                fontSize: "small",
                color: "GrayText",
                fontWeight: "normal",
              }}
            >
              Kleur glas
            </span>
          </div> */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={{
                fontSize: "14px",
                color: "GrayText",
                fontWeight: "bold",
              }}
            >
              Glas soort
            </span>
            <span>{techInformation.colorGlass}</span>
            {/* <span>&euro; 0,00</span> */}
          </div>
        </div>
      </div>
      <div style={{ width: "100%", marginTop: "15px" }}>
        <div
          style={{
            margin: "2% 5% 2% 5%",
            paddingTop: "0.5rem",
            fontWeight: "bolder",
            fontSize: "normal",
            borderBottom: "1px solid GrayText",
          }}
        >
          {/* <div>
            <span
              style={{
                fontSize: "small",
                color: "GrayText",
                fontWeight: "normal",
              }}
            >
              RAL Kleur
            </span>
          </div> */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={{
                fontSize: "14px",
                color: "GrayText",
                fontWeight: "bold",
              }}
            >
              Kleur
            </span>
            <span>{techInformation.steelColor}</span>
            {/* <span>&euro; 0,00</span> */}
          </div>
        </div>
      </div>
      <div style={{ width: "100%", marginTop: "15px" }}>
        <div
          style={{
            margin: "2% 5% 2% 5%",
            paddingTop: "0.5rem",
            fontWeight: "bolder",
            fontSize: "normal",
            borderBottom: "1px solid GrayText",
          }}
        >
          {/* <div>
            <span
              style={{
                fontSize: "small",
                color: "GrayText",
                fontWeight: "normal",
              }}
            >
              Verzending en montage
            </span>
          </div> */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={{
                fontSize: "14px",
                color: "GrayText",
                fontWeight: "bold",
              }}
            >
              Extra opties
            </span>
            <div
              style={{ display: "flex", flexDirection: "column", width: "80%" }}
            >
              {techInformation.extraOptions.map((extraOption, index) => {
                return (
                  <span style={{ fontSize: "14px" }} key={`extra-${index}`}>
                    - {extraOption}
                  </span>
                );
              })}
            </div>

            {/* <span>&euro; 0,00</span> */}
          </div>
        </div>
      </div>
    </>
  );
}
